import Vue from 'vue';
import { mediaUrl } from '@/config';

const mediaUrlDirective = {
    bind(el, binding) {
        if (!binding.value || !binding.value.type) {
            return null;
        }

        const {
            date,
            filename,
            extension,
            meta: { breakpoints = [] },
            type
        } = binding.value;

        el.className = 'media-container';

        if (type === 'video') {
            const url = `${mediaUrl}/videos/${date}/${filename}.${extension}`;
            const videoElement = document.createElement('video');
            videoElement.className = 'img-fluid img-thumbnail';
            videoElement.setAttribute('src', url);

            if (binding.modifiers.controls) {
                videoElement.controls = true;
                videoElement.className += ' video';
            }

            el.className = 'button';
            el.appendChild(videoElement);

            return;
        }

        if (type === 'image') {
            const breakpointsCopy = [...breakpoints].sort((a, b) => a - b);

            const imageUrl = `${mediaUrl}/images/${date}/${filename}${
                breakpointsCopy.length ? `_${breakpointsCopy[0]}` : ''
            }.${extension}`;

            const imgElement = document.createElement('img');
            imgElement.className = 'img-fluid img-thumbnail';
            imgElement.setAttribute('src', imageUrl);

            el.appendChild(imgElement);

            return;
        }

        const typeToIcon = {
            pdf: 'fas fa-file-pdf',
            doc: 'fas fa-file-word',
            text: 'fas fa-file-alt',
            ppt: 'fas fa-file-powerpoint',
            audio: 'fas fa-file-audio',
            archive: 'fas fa-file-archive'
        };

        const iconElement = document.createElement('i');
        iconElement.className = typeToIcon[type];
        iconElement.style = 'position: absolute; font-size: 35px';

        el.appendChild(iconElement);
    }
};

Vue.directive('media-url', mediaUrlDirective);
