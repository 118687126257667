<template>
    <div class="side-bar">
        <perfect-scrollbar>
            <ul>
                <li>
                    <router-link to="/" exact>
                        <i class="fas fa-home"></i>
                        <span>Dashboard</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/containers">
                        <i class="fas fa-ship"></i>
                        <span>Containers</span>
                    </router-link>
                </li>
            </ul>
        </perfect-scrollbar>
    </div>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';

export default {
    components: {
        PerfectScrollbar
    }
};
</script>
