<template>
    <tr v-if="isAnySum" class="sum-section">
        <td v-for="i in emptyCellsBefore" :key="i" />
        <td v-for="(label, index) in labels" :key="index + 'sum'">
            <span v-if="label.type === 'number' && label.sum">
                {{ sumLabel(label) }}
            </span>
            <span v-else>
                -
            </span>
        </td>
    </tr>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        labels: {
            type: Array,
            required: true
        },
        emptyCellsBefore: {
            type: Number,
            required: false,
            default: 0
        },
        getValue: {
            type: Function,
            required: true
        }
    },
    computed: {
        isAnySum() {
            return (
                this.items.length &&
                this.labels.some(label => label.type === 'number' && label.sum)
            );
        }
    },
    methods: {
        sumLabel(label) {
            const sum = this.items.reduce((accumulator, item) => {
                const value = this.getValue(item, label);

                return accumulator + (isNaN(value) ? 0 : parseFloat(value));
            }, 0);

            const sumCopy = parseInt(sum);

            return sumCopy === sum ? sum : sum.toFixed(2);
        }
    }
};
</script>
