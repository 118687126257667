import axios from 'axios';
import BaseCrud from './baseCrud';
const crud = new BaseCrud('container');

export const state = () => ({
    ...crud.state,
    ON_THE_WAY: 'on_the_way',
    ARRIVED: 'arrived',
    DONE: 'done'
});

export const getters = {
    ...crud.getters,
    ON_THE_WAY: state => state.ON_THE_WAY,
    ARRIVED: state => state.ARRIVED,
    DONE: state => state.DONE
};

export const mutations = {
    ...crud.mutations
};

export const actions = {
    ...crud.actions,

    index(
        vuexContext,
        {
            currentPage = 1,
            perPage = 10,
            search = '',
            sortBy = 'reportedAt',
            descending = true,
            filters,
            status = null
        }
    ) {
        return axios.$get('/containers', {
            params: {
                page: currentPage,
                perPage,
                q: search,
                sortBy,
                order: descending ? 'DESC' : 'ASC',
                filters: JSON.stringify(filters),
                status
            }
        });
    },

    track(vuexContext, number) {
        return axios.$post(`/containers/track`, { number });
    },

    untrack(vuexContext, id) {
        return axios.$delete(`/containers/${id}/untrack`);
    },

    async importCsv(vuexContext, csvFile) {
        const csvData = new FormData();

        csvData.append('csvFile', csvFile);

        await axios.post('/containers/import-csv', csvData);
    }
};
