<template>
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <div class="page-title-right">
                    <b-breadcrumb :items="items" class="m-0" />
                </div>
                <h4 class="page-title">
                    {{ title }}
                </h4>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        items: {
            type: Array,
            default: () => []
        }
    }
};
</script>
