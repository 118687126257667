<template>
    <button class="btn btn-primary mr-1" @click="generateCsv">
        Export CSV
    </button>
</template>

<script>
import { parse } from 'json2csv';
import * as Sentry from '@sentry/vue';
import getValueHelper from './helpers/get-value';
import getLabelHelper from './helpers/get-label';

export default {
    props: {
        labels: {
            type: Array,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        selected: {
            type: Array,
            required: true
        },
        tableOptions: {
            type: Object,
            required: true
        }
    },

    data() {
        const { selectField } = this.tableOptions;

        return {
            csvData: [],
            selectField
        };
    },

    watch: {
        selected: {
            handler(newVal, oldVal = []) {
                if (newVal.length > oldVal.length) {
                    const difference = this.getArraysDifference(newVal, oldVal);

                    this.addItems(difference);

                    return;
                }

                const difference = this.getArraysDifference(
                    this.csvData.map(item => item[this.selectField]),
                    newVal
                );

                this.removeItems(difference);
            },
            immediate: true
        }
    },

    methods: {
        generateCsv() {
            const labels = this.labels.filter(label => !label.isHidden);

            const opts = {
                fields: labels.map(label => ({
                    label: this.getLabel(label),
                    value: label.value || label
                }))
            };

            try {
                const content = parse(this.csvData, opts);
                const fileURL = window.URL.createObjectURL(
                    new Blob([`\ufeff${content}`])
                );
                const now = this.$dayjs().format('YYYY-MM-DD_hh:mm:ss');

                this.$downloadFile(fileURL, `Container Tracking_${now}.csv`);
            } catch (error) {
                console.error(error);

                Sentry.captureException(error);

                this.$toasterError();
            }
        },

        addItems(items) {
            const newItems = items.map(value => {
                const item = this.items.find(
                    i => i[this.selectField] === value
                );

                if (!item) {
                    return;
                }

                const preparedItem = {
                    [this.selectField]: this.getValue(item, this.selectField)
                };

                this.labels.forEach(label => {
                    preparedItem[label.value || label] = this.getValue(
                        item,
                        label
                    );
                });

                return preparedItem;
            });

            this.csvData.push(...newItems);
        },

        removeItems(items) {
            items.forEach(value => {
                const itemIndex = this.csvData.findIndex(
                    i => i[this.selectField] === value
                );

                if (~itemIndex) {
                    this.csvData.splice(itemIndex, 1);
                }
            });
        },

        getArraysDifference(arr1, arr2) {
            return arr1.filter(x => !arr2.includes(x));
        },

        getValue(item, label) {
            return getValueHelper(
                item,
                label,
                this.tableOptions,
                this.$options.filters
            );
        },

        getLabel(label) {
            return getLabelHelper(label);
        }
    }
};
</script>
