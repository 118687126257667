<template>
    <div class="nav-bar">
        <div class="ml-3 text-light nav-text">
            <router-link to="/">
                <span>Container Tracking</span>
            </router-link>
        </div>
        <div class="menu">
            <b-dropdown
                right
                class="notification-list"
                menu-class="profile-dropdown"
            >
                <template #button-content>
                    <div class="nav-user mr-0 waves-effect waves-light">
                        <img
                            :src="imageSource"
                            alt="user-image"
                            class="rounded-circle"
                        />
                    </div>
                </template>
                <b-dropdown-item to="/account">
                    <i class="far fa-user mr-2" />
                    <span>My account</span>
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item :to="{ name: 'logout' }">
                    <i class="fas fa-sign-out-alt mr-2" />
                    <span>Logout</span>
                </b-dropdown-item>
            </b-dropdown>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import getImageUrl from '@/helpers/getImageUrl';

export default {
    computed: {
        ...mapGetters({
            loggedUser: 'auth/loggedUser'
        }),

        imageSource() {
            const { avatar } = this.loggedUser;

            return getImageUrl(avatar, true);
        }
    }
};
</script>
