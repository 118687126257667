import _ from 'lodash';
import axios from 'axios';

export default class {
    constructor(
        singular,
        plural,
        singularVariable = null,
        pluralVariable = null
    ) {
        if (!plural) {
            plural = `${singular}s`;
        }

        this.state = {
            singular,
            plural,
            singularVariable: singularVariable || _.camelCase(singular),
            pluralVariable: pluralVariable || _.camelCase(plural)
        };
        this.getters = {};
        this.mutations = {};
        this.actions = {
            async index(
                { state },
                {
                    currentPage = 1,
                    perPage = 10,
                    search = '',
                    sortBy = 'createdAt',
                    descending = true,
                    filters = null
                }
            ) {
                const params = {
                    page: currentPage,
                    perPage,
                    q: search,
                    sortBy,
                    order: descending ? 'DESC' : 'ASC'
                };

                if (filters) {
                    params.filters = JSON.stringify(filters);
                }

                const data = await axios.$get(`/${state.plural}`, {
                    params
                });

                if (data[state.singularVariable]) {
                    return data[state.singularVariable];
                } else if (data[state.pluralVariable]) {
                    return data[state.pluralVariable];
                }

                return data;
            },

            async store({ state }, data) {
                const responseData = await axios.$post(
                    `/${state.plural}`,
                    data
                );

                if (responseData[state.singularVariable]) {
                    return responseData[state.singularVariable];
                } else if (responseData[state.pluralVariable]) {
                    return responseData[state.pluralVariable];
                }

                return responseData;
            },

            async show({ state }, id) {
                const data = await axios.$get(`/${state.plural}/${id}`);

                if (data[state.singularVariable]) {
                    return data[state.singularVariable];
                } else if (data[state.pluralVariable]) {
                    return data[state.pluralVariable];
                }

                return data;
            },

            async update({ state }, { id, data }) {
                const responseData = await axios.$put(
                    `/${state.plural}/${id}`,
                    data
                );

                if (responseData[state.singularVariable]) {
                    return responseData[state.singularVariable];
                } else if (responseData[state.pluralVariable]) {
                    return responseData[state.pluralVariable];
                }

                return responseData;
            },

            destroy({ state }, id) {
                return axios.$delete(`/${state.plural}/${id}`);
            }
        };
    }
}
