import Vue from 'vue';
import App from './App';
import config from './config';
import router from './router';
import store from './state/store';
import * as Sentry from '@sentry/vue';

require('./bootstrap/axios.config');
require('./bootstrap/di');
require('./bootstrap/global-components');
require('./bootstrap/global-filters');
require('./bootstrap/global-styles');
require('./bootstrap/global-directives');
require('./bootstrap/plugins');

Vue.config.productionTip = config.env === 'production';

try {
    window.$ = window.jQuery = require('jquery');
} catch (e) {
    console.error(e);

    Sentry.captureException(e);
}

new Vue({
    router,
    store,
    publicPath: config.publicPath,
    render: h => h(App)
}).$mount('#app');
