import { mediaUrl } from '@/config';

export default (image, isAvatar = false) => {
    if (!image) {
        return isAvatar ? require('@/assets/images/user.jpg') : '';
    }

    const {
        date,
        filename,
        extension,
        meta: { breakpoints = [] }
    } = image;

    const breakpointsCopy = [...breakpoints].sort((a, b) => a - b);

    return `${mediaUrl}/images/${date}/${filename}${
        breakpointsCopy.length ? `_${breakpointsCopy[0]}` : ''
    }.${extension}`;
};
