import Vue from 'vue';
import dayjs from 'dayjs';
import Vuelidate from 'vuelidate';
import BootstrapVue from 'bootstrap-vue';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { mediaUrl } from '@/config';

Vue.use(Vuelidate);

Vue.use(BootstrapVue);
Vue.prototype.$toaster = function(message, options = {}) {
    this.$bvToast.toast(message, {
        title: 'Success!',
        variant: 'success',
        solid: true,
        ...options
    });
};

Vue.prototype.$toasterError = function(message = null) {
    this.$bvToast.toast(message || 'Something went wrong', {
        title: 'Error!',
        variant: 'danger',
        solid: true
    });
};

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

Vue.prototype.$dayjs = dayjs;

Vue.prototype.$downloadFile = (href, name, blank = false) => {
    const fileLink = document.createElement('a');

    fileLink.href = href;
    fileLink.setAttribute('download', name);

    if (blank) {
        fileLink.setAttribute('target', '_blank');
    }

    fileLink.click();
};

Vue.prototype.$copyToClipboard = function(text) {
    const el = document.createElement('textarea');
    el.value = text;

    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');

    document.body.removeChild(el);

    this.$toaster('Copied to clipboard!', {
        title: 'Copied!'
    });
};

Vue.prototype.$getFileUrl = file => {
    if (!file) {
        return '';
    }

    const { type, date, filename, extension } = file;

    return `${mediaUrl}/${type}s/${date}/${filename}.${extension}`;
};
