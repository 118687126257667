<template>
    <div>
        <b-form-checkbox v-model="value" switch @input="onFilterChange" />
        <i v-if="value !== null" class="fas fa-trash" @click="clearValue" />
    </div>
</template>

<script>
export default {
    props: {
        default: {
            type: Object,
            required: false,
            default: null
        }
    },

    data() {
        return {
            value: null
        };
    },

    created() {
        if (this.default) {
            this.value = this.default.value === 1;
        }
    },

    methods: {
        onFilterChange() {
            const filter = {
                value: this.value ? 1 : this.value !== null ? 0 : null,
                type: 'eq'
            };

            this.$emit('filter-change', filter);
        },

        clearValue() {
            this.value = null;
            this.onFilterChange();
        }
    }
};
</script>
