<template>
    <div class="row">
        <div class="col-xl-9 mb-3">
            <div class="card h-100">
                <div class="card-body">
                    <b-skeleton
                        v-for="index in elementsCount"
                        :key="index"
                        :style="{ margin: `${getRandomNumber(10, 50)}px 20px` }"
                        animation="wave"
                        :type="getRandomType()"
                        :width="`${getRandomNumber(20, 90)}%`"
                    />
                </div>
            </div>
        </div>

        <div class="col-xl-3 mb-3">
            <div class="card">
                <div class="card-body">
                    <b-skeleton
                        v-for="index in parseInt(elementsCount / 2)"
                        :key="index"
                        :style="{ margin: `${getRandomNumber(10, 50)}px 20px` }"
                        animation="wave"
                        :type="getRandomType()"
                        :width="`${getRandomNumber(20, 90)}%`"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        elementsCount: {
            type: Number,
            required: false,
            default: parseInt(Math.random() * 5 + 7)
        }
    },

    data() {
        return {
            types: ['text', 'input', 'button']
        };
    },

    methods: {
        getRandomNumber(min, max) {
            return parseInt(Math.random() * (max - min) + min);
        },

        getRandomType() {
            return this.types[this.getRandomNumber(0, this.types.length - 1)];
        }
    }
};
</script>
