<template>
    <div class="wrapper">
        <navbar />
        <sidebar />

        <div class="content-page">
            <div class="content">
                <div class="container-fluid">
                    <slot />
                </div>
                <footer class="footer footer-alt footer-main">
                    2021 © Container Tracking
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar';
import Sidebar from '@/components/layout/Sidebar';

export default {
    components: {
        Navbar,
        Sidebar
    }
};
</script>
