import store from '@/state/store';

const crudElements = [];

const crudRoutes = crudElements.flatMap(route => [
    {
        path: `/${route}`,
        name: route,
        component: () => import(`@/views/${route}/Table`),
        meta: {
            authRequired: true
        }
    },
    {
        path: `/${route}/add`,
        name: `${route}Add`,
        component: () => import(`@/views/${route}/AddEdit`),
        meta: {
            authRequired: true
        }
    },
    {
        path: `/${route}/:id/edit`,
        name: `${route}Edit`,
        component: () => import(`@/views/${route}/AddEdit`),
        meta: {
            authRequired: true
        }
    }
]);

export default [
    ...crudRoutes,

    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth/Login'),
        meta: {
            guestOnly: true,
            layout: 'auth'
        }
    },
    {
        path: '/sign-up',
        name: 'sign-up',
        component: () => import('@/views/auth/SignUp'),
        meta: {
            guestOnly: true,
            layout: 'auth'
        }
    },
    {
        path: '/set-password/:token',
        name: 'setPassword',
        component: () => import('@/views/auth/ResetPassword'),
        meta: {
            guestOnly: true,
            layout: 'auth'
        }
    },
    {
        path: '/reset-password/:token',
        name: 'resetPassword',
        component: () => import('@/views/auth/ResetPassword'),
        meta: {
            guestOnly: true,
            layout: 'auth'
        }
    },
    {
        path: '/forget-password',
        name: 'forgetPassword',
        component: () => import('@/views/auth/ForgetPassword'),
        meta: {
            guestOnly: true,
            layout: 'auth'
        }
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve() {
                store.dispatch('auth/logout');
            }
        }
    },

    {
        path: '/',
        name: 'dashboard',
        component: () => import('@/views/dashboard/Dashboard'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/containers',
        name: 'containers',
        component: () => import('@/views/containers/Board'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/containers/:id',
        name: 'singleContainer',
        component: () => import('@/views/containers/Show'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/account',
        name: 'account',
        component: () => import('@/views/account/Account'),
        meta: {
            authRequired: true
        }
    },

    {
        path: '/user-session/:id',
        name: 'userSession',
        component: () => import('@/views/user-session/UserSession')
    },

    {
        path: '*',
        name: 'notFoundPage',
        component: () => import('@/layouts/error'),
        meta: {
            layout: 'error'
        }
    }
];
