import Vue from 'vue';
import vSelect from 'vue-select';
import PageHeader from '@/components/PageHeader';
import AwesomeTable from '@/components/awesome-table';
import SkeletonLoader from '@/components/elements/SkeletonLoader';

Vue.component('VSelect', vSelect);
Vue.component('PageHeader', PageHeader);
Vue.component('AwesomeTable', AwesomeTable);
Vue.component('SkeletonLoader', SkeletonLoader);
