<script>
import Base from './Base';

export default {
    extends: Base,

    data() {
        return {
            type: {
                text: 'Equals',
                value: 'eq'
            },
            types: [
                {
                    text: 'Equals',
                    value: 'eq'
                },
                {
                    text: 'Is not',
                    value: 'not'
                },
                {
                    text: 'Greater than',
                    value: 'gt'
                },
                {
                    text: 'Greater than or equal',
                    value: 'gte'
                },
                {
                    text: 'Less than',
                    value: 'lt'
                },
                {
                    text: 'Less than or equal',
                    value: 'lte'
                }
            ],
            inputType: 'number'
        };
    }
};
</script>
